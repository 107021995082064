import {
  createRouter,
  createWebHistory
} from "vue-router";


const routes = [{
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/records",
    name: "records",
    component: () => import("../views/RecordsView.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/PaymentView.vue"),
  },
  {
    path: "/records",
    name: "records",
    component: () => import("../views/RecordsView.vue"),
  },
  {
    path: "/advance",
    name: "advance",
    component: () => import("../views/AdvanceView.vue"),
  },
  {
    path: "/act",
    name: "act",
    component: () => import("../views/ActView.vue"),
  }
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const savedRoute = localStorage.getItem('currentRoute')
if (savedRoute) {
  router.replace(savedRoute)
}


router.beforeEach((to, from) => {
  localStorage.setItem('currentRoute', to.fullPath)
})


export default router;