import {
  createApp
} from "vue";
import App from "./App.vue";
import router from "./router";


import PrimeVue from "primevue/config";

import {
  primeLocaleSettings
} from "./i18n/primeLocale";

createApp(App)
  .use(router)
  .use(PrimeVue, {
    locale: primeLocaleSettings,
  })
  .mount("#app");

import "./common/styles/main.scss";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";