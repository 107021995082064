 import {
     msg
 } from '@/helpers/postData'
 export const checkConnection = async () => {
     const JWToken = sessionStorage.getItem('_token1c')
     const id = sessionStorage.getItem('_company-id')
     const globalToken = sessionStorage.getItem('_token')
     try {
         const res = await fetch(`${window.apiUrl}1c/ping?company=${id}`, {
             timeout: 100000,
             method: 'POST',
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": `Bearer ${globalToken}`,
                 JWToken
             },

         })
         const isConnect = await res.json()
         if (typeof isConnect === 'string') {
             msg(isConnect)
         }
         if (typeof isConnect === 'object') {
             msg(isConnect.message)
         }
         if (res.status === 200) {
             return isConnect
         } else {
             console.error('Ошибка запроса:', res.status, res.statusText, res.url);
             msg(`Ошибка запроса ${res.status}: ${res.statusText}`)
         }
         console.log('Соединение с 1С успешно', isConnect);
     } catch (error) {
         console.error('Ошибка при проверке соединения с 1С', error);
     }
 }