<template>
  <div v-cloak v-if="isDone">
    <router-view :token="token1c" :id="companyID" />
  </div>
</template>

<script>
import { getTokenLocal } from "@/api/auth.js";
import { checkConnection } from "@/api/ping";
import { msg } from "@/helpers/postData";

export default {
  name: "App",
  data() {
    return {
      companyID: "",
      tokenGlobal: null,
      token1c: "",
      isDone: false,
    };
  },
  methods: {
    getTokenAndId(string = "") {
      let strArr = string.split("?").filter((el) => Boolean(el) === true);
      const obj = {};
      for (const key of strArr) {
        const subkeys = key.split("=");
        obj[subkeys[0]] = subkeys[1];
      }
      return obj;
    },
    createGlobalValue() {
      let data = this.getTokenAndId(location.search);
      this.companyID = data.company;
      this.tokenGlobal = data.globalToken;
    },
    setSessionStorageValue(name, value) {
      sessionStorage.setItem(name, value);
    },
    clearSessionStorageValue(name) {
      sessionStorage.removeItem(name);
    },
  },
  async created() {
    if (Boolean(location.search)) {
      await this.createGlobalValue();
      await this.setSessionStorageValue("_token", this.tokenGlobal);
      await this.setSessionStorageValue("_company-id", this.companyID);
      await this.setSessionStorageValue("_token1c", await getTokenLocal());
      this.isDone = await checkConnection();
    } else {
      msg("Нет данных для авторизации, попробуйте позднее");
    }
  },
  unmounted() {
    this.clearSessionStorageValue("_token");
    this.clearSessionStorageValue("_company-id");
    this.clearSessionStorageValue("_token1c");
  },
};
</script>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primevue/resources/themes/md-light-deeppurple/theme.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
[v-cloak] {
  display: none;
}
</style>
