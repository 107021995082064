import {
    msg
} from '@/helpers/postData'


export const getTokenLocal = async () => {
    const token = sessionStorage.getItem('_token')
    const id = sessionStorage.getItem('_company-id')
    try {
        const res = await fetch(`${window.apiUrl}1c/identification?company=${id}`, {
            timeout: 100000,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },

        })
        if (res.status === 200) {
            console.log('Токен авторизации 1C получен успешно');
            const token = await res.json()
            return token
        } else {
            console.error('Ошибка запроса:', res.status, res.statusText, res.url);
            msg(`Ошибка запроса ${res.status}: ${res.statusText}`)
        }

    } catch (error) {
        console.error('Ошибка авторизации 1С', error);
    }
}